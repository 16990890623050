<template>
  <div class="alert flex justify-center bg-red-200">
    <div class="relative bg-green-450 shadow-2xl flex items-start p-4 pr-2 rounded-md">
      <div class="flex items-center px-8 justify-between">
        <img
          src="@/assets/min/successfulAlertIcon.svg"
          alt="success icon"
        >
        <div class="text-center text-green-999 pl-8 pr-10">
          <p>تم انهاء تنفيذ الطلب بنجاح!</p>
          الوقت المستغرق {{ orderTime }} دقيقة
        </div>
      </div>
      <img
        class="cursor-pointer"
        src="@/assets/min/AlertClosingIcon.svg"
        alt="closing icon"
        @click="emitCloseAlertEvent()"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderTime: {
      type: String,
      required: true,
    },
  },
  emits: ['closeAlertClick'],

  methods: {
    emitCloseAlertEvent() {
      this.$emit('closeAlertClick');
    },
  },
};
</script>

<style scoped>
.alert {
  position: absolute;
  max-width: fit-content;
  top: 15vh;
  right: 35vw;
}
</style>
