import firebase from 'firebase/app';
import 'firebase/firebase-messaging';

const ENV = process.env;

const firebaseConfig = {
  apiKey: ENV.VUE_APP_apiKey,
  authDomain: ENV.VUE_APP_authDomain,
  databaseURL: ENV.VUE_APP_databaseURL,
  projectId: ENV.VUE_APP_projectId,
  storageBucket: ENV.VUE_APP_storageBucket,
  messagingSenderId: ENV.VUE_APP_messagingSenderId,
  appId: ENV.VUE_APP_appId,
  measurementId: ENV.VUE_APP_measurementId,
};

firebase.initializeApp(firebaseConfig);

export default firebase.messaging();
