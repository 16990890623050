<template>
  <div class="item-container grid grid-cols-2 gap-10 items-start">
    <div
      v-for="(order, index) in orders"
      :id="'id' + index"
      :key="order.number"
      class="w-full order-wrapper text-white p-1 rounded-2.8xs h-28"
      @click="notifyOrderSelection(order, index)"
    >
      <div
        class="leading-relaxed [ h-full cursor-pointer ] [ grid grid-cols-2 gap-x-6 ] [ p-6 rounded-2xs ]"
        :class="{
          'bg-red-600': order.slaStatus == 'out-of-sla',
          'bg-orange-100': order.slaStatus == 'mid-of-sla',
          'bg-primary-900': order.slaStatus == 'in-sla',
        }"
      >
        <p class="flex flex-col justify-between truncate">
          <span class="font-bold block text-font22">{{ order?.kitchen_picker?.name_ar }}</span>
          <span class="text-font22 date font-bold"> {{ order.time }}</span>
        </p>
        <p class="text-left flex flex-col justify-between">
          <span class="text-font22 block">طلب رقم</span>
          <span class="text-font22 font-bold">#{{ order.order_number }} </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      required: true,
    },
    currentOrderIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ['orderSelection'],
  data: () => {
    return {
      previousId: '',
      currentId: '',
    };
  },

  methods: {
    notifyOrderSelection(order, index) {
      this.previousId = this.currentId;
      if (this.previousId) document.querySelector(this.previousId).classList.remove('selected-order-wrapper');
      this.currentId = '#id' + index;
      document.querySelector(this.currentId).classList.add('selected-order-wrapper');
      this.$emit('orderSelection', { order: order, orderIndex: index });
    },

    selectThePulledOrder(index) {
      setTimeout(() => {
        this.previousId = this.currentId;
        if (this.previousId) document.querySelector(this.previousId).classList.remove('selected-order-wrapper');
        this.currentId = '#id' + index;
        document.querySelector(this.currentId).classList.add('selected-order-wrapper');
      }, 1000);
    },
  },
};
</script>

<style>
.order-wrapper {
  border: 5px solid transparent;
}

.selected-order-wrapper {
  @apply border-primary-900 border-3;
  border-radius: 20px;
}

.item-container {
  grid-auto-rows: minmax(min-content, max-content);
}
</style>
