<template>
  <div class="order-details-component flex justify-between flex-col rounded-2.5xs bg-white">
    <div>
      <div
        class="bg-red-450 order-details-header rounded-t-2.5xs flex justify-between py-4 px-5 text-white"
        :class="{
          'bg-red-600': order.slaStatus == 'out-of-sla',
          'bg-orange-100': order.slaStatus == 'mid-of-sla',
          'bg-primary-900': order.slaStatus == 'in-sla',
        }"
      >
        <p>
          <span class="font-bold block text-font27">{{ order?.kitchen_picker?.name_ar }}</span>
          <span class="flex items-end date text-font22"> {{ order.time }}</span>
        </p>
        <p>
          <span class="block text-font22">طلب رقم </span>
          <span class="font-bold number text-font22">{{ order.order_number }}</span>
        </p>
      </div>

      <div class="px-5">
        <section v-for="(item, itemIndex) in items" :key="itemIndex + item.id" class="border-b border-gray-300 py-2">
          <div class="flex justify-between items-center py-2">
            <section
              class="leading-loose w-full flex-1"
              :class="[item.status == 'cancelled' ? 'line-through opacity-50' : '']"
            >
              <span class="font-bold text-font22">
                {{ item.displayedQuantity }}
              </span>

              <span v-if="item.unit == 'G'" class="font-bold text-font22"> جرام </span>
              <span v-else-if="item.unit == 'KG'" class="font-bold text-font22"> كيلو </span>

              <span class="font-bold text-font22"> - {{ item.name }} </span>
              <p v-if="item.customer_comment">"{{ item.customer_comment }}"</p>
            </section>

            <section v-if="!item.needRemoval" class="flex items-center">
              <div v-show="item.status != 'NOT_PICKED' && item.is_prepared == 1" class="flex">
                <button
                  class="w-10 h-10 rounded-full bg-primary-200 ml-2"
                  @click="changeItemStatus(itemIndex, item, 'IN_PROGRESS')"
                >
                  <img src="@/assets/min/confirm-active.svg" class="cursor-pointer" />
                </button>
                <button
                  class="w-10 h-10 rounded-full bg-primary-200 ml-2 danger"
                  @click="changeItemStatus(itemIndex, item, 'NOT_PICKED')"
                >
                  <img src="@/assets/min/cancel.svg" class="cursor-pointer" />
                </button>
              </div>

              <div v-show="item.status == 'NOT_PICKED' && item.is_prepared == 1" class="flex">
                <button
                  class="w-10 h-10 rounded-full bg-light-red-200 ml-2 danger"
                  @click="changeItemStatus(itemIndex, item, 'IN_PROGRESS')"
                >
                  <img src="@/assets/min/confirm.svg" class="cursor-pointer" />
                </button>
                <img src="@/assets/min/cancel-active.svg" />
              </div>

              <div v-show="item.is_prepared != 1" class="flex">
                <button
                  class="w-10 h-10 rounded-full bg-primary-200 ml-2"
                  @click="changeItemStatus(itemIndex, item, 'IN_PROGRESS')"
                >
                  <img src="@/assets/min/confirm.svg" alt="Confirm Icon" class="cursor-pointer" />
                </button>
                <button
                  class="w-10 h-10 rounded-full bg-light-red-200 ml-2 danger"
                  @click="changeItemStatus(itemIndex, item, 'NOT_PICKED')"
                >
                  <img src="@/assets/min/cancel.svg" alt="Cancel Icon" class="cursor-pointer" />
                </button>
              </div>
            </section>
          </div>
          <template v-if="item.status === 'NOT_PICKED'">
            <details class="my-2 bg-primary-300 rounded-lg">
              <summary class="bg-primary-900 p-3 rounded-lg font-bold text-white text-lg cursor-pointer">
                البدائل المتاحة
              </summary>
              <div class="p-3">
                <ItemAlternatives
                  v-if="item.alternatives?.length"
                  :alternatives="item.alternatives"
                  :item-id="item.id"
                  @setItemAlternatives="handleAlternatives"
                />
                <p v-else>لا يوجد بدائل</p>
              </div>
            </details>
          </template>
          <div v-if="item.needRemoval" class="w-3/5 mx-auto">
            <p class="text-sm bg-red-300 rounded-lg py-1 text-red-900 text-center font-bold">جاري استرجاع الكمية</p>
          </div>
          <!-- <template v-if="item.isDone">
            <div
              v-if="item.isDone"
              class="w-1/2 mx-auto"
            >
              <p class="text-sm bg-green-450 rounded-lg py-1 text-center font-bold">
                تم تحضير هذا العنصر
              </p>
            </div>
          </template> -->
        </section>
      </div>
    </div>

    <div class="mt-5 bg-white shadow-custom-1 rounded-3xs p-5">
      <button
        class="
          finish-order-btn
          bg-primary-700
          mx-auto
          w-full
          py-3
          block
          text-white
          shadow-xl
          focus:outline-none
          rounded-2.8xs
          font-bold
          text-xl
        "
        :disabled="!canFinish"
        @click="emitFinishOrderEvent()"
      >
        إنهاء الطلب
      </button>
    </div>
  </div>
</template>

<script>
import ItemAlternatives from '@/components/ItemAlternatives';
import axios from 'axios';
let instance = '';

export default {
  components: {
    ItemAlternatives,
  },
  props: {
    order: { type: Object, required: true },
    tolerance: {
      type: Number,
      required: true,
    },
  },
  emits: ['newOrderStatus', 'finishOrderClick'],

  data() {
    return {
      items: '',
      verifiedQuantity: [],
    };
  },

  computed: {
    canFinish() {
      if (this.items.length) {
        return this.items.every(item => {
          return item.is_prepared == 1;
        });
      } else {
        return false;
      }
    },
  },

  mounted: function () {
    this.items = this.order.items;

    this.items.forEach(item => {
      const quantityDifference = item.qty - item.picked_qty;
      const margin = this.tolerance * item.qty;

      if (Math.abs(quantityDifference) > margin) {
        item.displayedQuantity = Math.abs(quantityDifference).toFixed(3);
        item.needRemoval = quantityDifference < margin;
      } else {
        item.displayedQuantity = item.qty.toFixed(3);
        item.isDone = true;
      }
    });

    this.configureAxiosInstance();

    setTimeout(() => {
      document.querySelector('input[type=text]').focus();
    }, 50);
  },

  methods: {
    configureAxiosInstance() {
      instance = axios.create({
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${
            document.cookie
              .split('; ')
              .filter(row => row.startsWith('access'))[0]
              .split('=')[1]
          }`,
        },
      });
    },
    changeItemStatus(index, item, newStatus) {
      this.$emit('newOrderStatus', {
        itemNumber: index,
        itemID: item.id,
        itemQuantity: parseInt(this.verifiedQuantity[index])
          ? parseInt(item.picked_qty * (item.unit == 'G' ? 1 : 1000)) + parseInt(this.verifiedQuantity[index])
          : item.qty * (item.unit == 'G' ? 1 : 1000),
        itemStatus: newStatus,
      });

      if (newStatus === 'NOT_PICKED') {
        instance.get(`/api/item/${item.id}/alt-products`).then(response => {
          this.items[index].alternatives = response.data.data;
        });
      }
    },

    setItemAlternatives(item) {
      this.$emit('setItemAlternatives', {
        orderItemId: item.id,
        code: '',
      });
    },

    handleAlternatives(e) {
      instance.post(`/api/item/${e.itemId}/kitchen/alt-products`, {
        orderItemId: e.itemId,
        code: e.skus,
      });
    },

    updateQuantity(index) {
      if (this.verifiedQuantity[index]) {
        this.items[index].picked_qty += this.verifiedQuantity[index] / (this.items[index].unit == 'G' ? 1 : 1000);
        this.verifiedQuantity[index] = '';
      }
    },

    emitFinishOrderEvent() {
      this.$emit('finishOrderClick', { id: this.order.id });
    },
  },
};
</script>

<style scoped>
.finish-order-btn:active {
  box-shadow: none;
}

.order-details-header > p {
  line-height: 2;
}

.order-details-component {
  box-shadow: 20px 0px 30px rgba(82, 80, 80, 0.1);
  min-height: 555px;
}
</style>
