<template>
  <div class="bg-primary-300 rounded-b-3xl">
    <div class="flex justify-between">
      <div class="flex justify-between">
        <div class="menu-section flex bg-colorful-primary-700 rounded-br-3xl">
          <img
            src="@/assets/min/menu.svg"
            class="bg-green-950 p-5 inline cursor-pointer"
            alt="Toggle Menu Sign"
            @click="emitOpenMenuEvent"
          >

          <div class="h-full w-unit flex-none bg-white" />
          <div
            class="
              inline-block
              text-font27
              whitespace-nowrap
              text-white
              [
              flex
              items-center
              ]
              py-6
              px-3
              lg:p-6
              text-center
              font-bold
            "
          >
            <!-- <img
            src="@/assets/min/mark.svg"
            alt=""
            class="w-7 h-5 ml-4"
          > -->
            {{ categoryName }}
          </div>
        </div>
        <div class="flex items-center justify-center mr-2 lg:mr-10">
          <span class="text-xl mx-2 font-bold text-dark-grey-900">طلبات قيد التحضير </span>
          <div class="[ flex items-center justify-center ] bg-primary-900 text-white w-6 h-6 rounded-full font-bold">
            {{ inPreparationOrdersCount }}
          </div>
        </div>
      </div>

      <div class="flex justify-evenly xl:w-3/12 md:w-4/12 sm:w-5/12 items-center">
        <button
          class="refresh-btn focus:outline-none [ flex items-center justify-between ]"
          @click="emitRefreshEvent()"
        >
          <img
            src="@/assets/min/refresh.svg"
            alt="Refresh Arrows"
          >
          <span class="mr-2 text-primary-900 text-2xl font-bold"> تحديث </span>
        </button>

        <button
          class="
            pull-btn
            py-2
            pl-2
            lg:px-14
            flex
            items-center
            bg-colorful-primary-700
            text-white
            rounded-full
            focus:outline-none
          "
          :disabled="!allOrdersCount"
          :class="[allOrdersCount ? 'shadow-xl' : '']"
          @click="emitNewOrderEvent()"
        >
          <span class="new-order-content mx-2 font-bold text-white text-font22"> ابدأ طلب جديد </span>
          <!-- TODO order count -->
          <div
            class="py-1 bg-white text-primary-900 rounded-full font-bold"
            :class="{
              'px-2': allOrdersCount > 9,
              'px-3': allOrdersCount < 10,
            }"
          >
            {{ allOrdersCount }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoryName: { type: String, required: true },
    allOrdersCount: { type: Number, required: true },
    inPreparationOrdersCount: { type: Number, required: true },
  },
  emits: ['openMenuClick', 'newOrderClick', 'refreshClick'],
  methods: {
    emitOpenMenuEvent(e) {
      this.$emit('openMenuClick');
      e.stopPropagation();
    },

    emitNewOrderEvent() {
      this.$emit('newOrderClick');
    },

    emitRefreshEvent() {
      this.$emit('refreshClick');
    },
  },
};
</script>

<style>
.mark {
  margin: auto;
}

.pull-btn:active {
  box-shadow: none;
  outline: none;
  background-color: #005931;
}

.refresh-btn:active {
  box-shadow: none;
  outline: none;
}

.refresh-btn:hover {
  background: white;
}

.bg-colorful-primary-700 {
  background: linear-gradient(180deg, #95c2b8 0%, #258470 100%, #007058 100%);
}
</style>
