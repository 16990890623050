<template>
  <div class="w-full h-full flex items-center justify-center bg-primary-900">
    <img
      src="@/assets/svg/login-art-1.svg"
      alt=""
      role="presentation"
      class="absolute bottom-0 left-0"
    >
    <div class="flex flex-col justify-center items-center text-white">
      <div class="w-96">
        <!-- TODO logo -->
        <img
          src="@/assets/svg/logo.svg"
          alt=""
          class="w-44 h-28 mx-auto"
        >

        <form @submit.prevent="getAuth()">
          <div class="my-6 flex flex-col">
            <label
              class="p-2"
              for="branch"
            >* اختر الفرع</label>
            <div class="select-wrapper">
              <select
                v-model="selectedBranch"
                class="branch-menu w-full py-4 px-4 rounded-3xs text-black bg-gray-150 outline-none"
                name="branch"
                required
              >
                <option
                  value=""
                  selected
                  disabled
                  hidden
                >
                  إختر ...
                </option>
                <option
                  v-for="branch in branchesList"
                  :key="branch.id"
                  :value="branch.id"
                >
                  {{ branch.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="my-6 flex flex-col">
            <div class="p-2">
              <label for="password">*ادخل كلمه السر</label>
            </div>
            <input
              v-model="password"
              class="py-4 px-4 rounded-3xs bg-white text-black outline-none"
              type="password"
              name="password"
              required
            >
          </div>

          <p
            v-if="invalidPassword"
            class="text-red-700"
          >
            حدث خطأ في تسجيل الدخول، برجاء التأكد من كلمة المرور
          </p>
          <input
            class="
              py-3
              px-8
              my-2
              w-52
              mx-auto
              block
              justify-center
              rounded-3xs
              border border-white
              bg-transparent
              text-white
              outline-none
              shadow-xl
              cursor-pointer
              font-bold
              text-font22
            "
            type="submit"
            value="تسجيل دخول"
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
let instance = '';

export default {
  name: 'Login',
  emits: ['setAuthentication'],
  data: () => {
    return {
      branchesList: '',
      password: '',
      selectedBranch: '',
      invalidPassword: false,
    };
  },

  mounted: function () {
    this.configureAxiosInstance();
    this.getLocationDetails();
  },

  methods: {
    configureAxiosInstance() {
      instance = axios.create({
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },

    getAuth() {
      return instance({
        method: 'post',
        url: '/api/oauth/token',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          grant_type: 'password',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          username: this.selectedBranch.toString(),
          password: this.password,
          scope: '',
        },
      })
        .then(response => {
          this.$emit('setAuthentication', response.data);
        })
        .catch(() => {
          this.invalidPassword = true;
          document.querySelector('input[type=password]').style.border = '1px solid red';

          setTimeout(() => {
            this.invalidPassword = false;
            this.password = '';
            document.querySelector('input[type=password]').focus();
            document.querySelector('input[type=password]').style.border = '';
          }, 3000);
        });
    },

    getLocationDetails() {
      instance.get('/api/locations').then(response => {
        this.branchesList = response.data;
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
input[type='submit']:active {
  box-shadow: none;
}

label {
  @apply text-lg;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  @apply text-primary-900;
  content: url('assets/svg/chveron-down.svg');
  font-size: 1rem;
  top: 50%;
  left: 15px;
  position: absolute;
  transform: translateY(-50%);
}
</style>
