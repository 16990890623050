<template>
  <div class="flex flex-col">
    <input
      v-model="code"
      type="number"
      placeholder="1234"
      required
      class="px-4 py-3 block border text-font22 border-dark-grey-300 rounded-2.5xs outline-none shadow-custom-2 w-full"
    >

    <div class="pin-code-panel mt-5 grid grid-cols-3 gap-4 px-11">
      <button
        v-for="(digit, index) in digits"
        :key="index"
        type="button"
        :aria-label="digit"
        class="
          rounded-2.5xs
          bg-layout-1
          w-full
          h-12
          [
          flex
          items-center
          justify-center
          ]
          [
          font-bold
          text-font27 text-dark-grey-900
          ]
        "
        @click="handleClickOn(digit)"
      >
        {{ digit }}
      </button>

      <button
        type="button"
        class="
          rounded-2.5xs
          bg-light-red-100
          w-full
          h-12
          [
          flex
          items-center
          justify-center
          ]
          [
          font-bold
          text-font27 text-dark-grey-900
          danger
          ]
        "
        :disabled="code.length < 1"
        @click="handleClear()"
      >
        <img
          src="@/assets/min/clear.svg"
          alt=""
        >
      </button>

      <button
        type="button"
        class="
          rounded-2.5xs
          bg-layout-1
          w-full
          h-12
          [
          flex
          items-center
          justify-center
          ]
          [
          font-bold
          text-font27 text-dark-grey-900
          ]
        "
        @click="handleClickOn(0)"
      >
        0
      </button>
      <button
        type="button"
        class="
          rounded-2.5xs
          bg-primary-200
          w-full
          h-12
          [
          flex
          items-center
          justify-center
          ]
          [
          font-bold
          text-font27 text-dark-grey-900
          ]
        "
        :disabled="code.length < 4"
        @click="handleSubmit()"
      >
        <img
          src="@/assets/min/confirm.svg"
          alt=""
        >
      </button>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['submit'],
  data: () => {
    return {
      digits: Array.from(Array(10).keys()).splice(1, 9),
      code: '',
    };
  },
  watch: {
    code: function (newValue) {
      if (newValue === 0) {
        this.code = 0;
        return;
      }
      this.code = newValue && Number.isFinite(Number(newValue)) ? newValue : '';
    },
  },
  methods: {
    handleClickOn(digit) {
      this.code = this.code + '' + digit;
    },
    handleClear() {
      this.code = '';
    },
    handleSubmit() {
      this.$emit('submit', this.code);
    },
  },
};
</script>
<style lang="postcss" scoped>
.pin-code-panel {
  direction: ltr;
}
</style>
