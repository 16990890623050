<template>
  <div class="absolute top-0 z-50 w-full h-full">
    <div class="absolute w-full h-full bg-black opacity-50" />
    <div class="card bg-white p-10 mx-auto z-50 fixed">
      <img
        src="@/assets/min/StartOrderModalClosingIcon.svg"
        class="cursor-pointer text-primary-900 [ absolute top-5 right-5 ]"
        alt="closing icon"
        @click="emitCloseModalEvent()"
      >

      <div class="py-3">
        <p class="mb-4 text-center text-dark-grey-900 font-bold text-font27">
          أدخل الكود الخاص بك
        </p>

        <form
          class="my-4"
          action=""
          @submit.prevent="startOrderWithCode"
        >
          <div>
            <label
              class="block text-dark-grey-900 text-font22"
              for="code"
            >الكود</label>

            <PinCodePanel
              class="mt-2"
              @submit="
                c => {
                  code = c;
                  startOrderWithCode();
                }
              "
            />
          </div>

          <!-- <input
            class="
              start-order-btn
              bg-green-750
              text-white
              px-6
              py-2
              rounded-lg
              shadow-xl
              focus:outline-none
              cursor-pointer
            "
            type="submit"
            value="ابدأ الطلب"
          > -->
        </form>

        <p
          v-if="!validCode"
          class="text-md text-red-600"
        >
          لقد قمت بإدخال كود خطأ، برجاء التأكد و إعادة المحاولة
        </p>
        <p
          v-if="!canStart"
          class="text-md text-red-600"
        >
          هذا كود غير مسموح به في هذا القسم، أو يوجد طلب قيد التحضير
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PinCodePanel from './PinCodePanel.vue';
export default {
  components: {
    PinCodePanel,
  },
  props: {
    validCode: {
      type: Boolean,
      required: true,
    },
    canStart: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['closeModalClick', 'newOrderCode'],

  data: () => {
    return {
      code: '',
    };
  },

  mounted: function () {
    // document.querySelector('input[type=text]').focus();
  },

  methods: {
    emitCloseModalEvent() {
      this.$emit('closeModalClick');
    },

    startOrderWithCode() {
      if (this.code) {
        this.$emit('newOrderCode', { code: this.code });
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.card {
  @apply rounded-2.5xs shadow-custom-2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 576px;
  width: 434px;
}
</style>
