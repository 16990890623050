<template>
  <div
    v-for="alternative in alternatives"
    :key="alternative.id"
    class="mb-2"
  >
    <input
      :id="alternative.sku"
      v-model="chosenAlternatives"
      type="checkbox"
      name="alternatives"
      :value="alternative.sku"
      class="ml-2"
      @change="setNewAlternatives"
    >
    <label
      :for="alternative.sku"
      class="text-lg"
    > {{ alternative.name }}</label>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    itemId: { type: Number, required: true },
    alternatives: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['setItemAlternatives'],
  data() {
    return {
      chosenAlternatives: [],
    };
  },
  methods: {
    setNewAlternatives: debounce(function () {
      this.$emit('setItemAlternatives', {
        itemId: this.itemId,
        skus: this.chosenAlternatives.join(','),
      });
    }, 1000),
  },
};
</script>
