<template>
  <div class="w-full h-full">
    <div class="absolute sidebar-container z-40 opened-menu bg-primary-900 text-white [ flex flex-col ]">
      <div class="flex items-start">
        <img
          src="@/assets/min/close.svg"
          class="cursor-pointer w-5 h-5 mr-5 mt-5"
          alt="Close icon"
          @click="emitCloseMenuEvent()"
        >
      </div>

      <div>
        <h3 class="mt-5 text-primary-600 font-bold text-font22">
          الأقسام
        </h3>
        <ul>
          <li @click="notifyCategortySelection(1)">
            <a> قسم اللحوم</a>
          </li>
          <li @click="notifyCategortySelection(2)">
            <a> قسم الجبن</a>
          </li>
          <li @click="notifyCategortySelection(3)">
            <a> قسم الأسماك</a>
          </li>
          <li @click="notifyCategortySelection(4)">
            <a> قسم الدواجن</a>
          </li>
        </ul>
      </div>

      <div class="mt-auto">
        <ul>
          <li @click="logout">
            <a class="logout">تسجيل الخروج</a>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="w-full h-full bg-black opacity-50 z-30 absolute inset-0"
      @click="emitCloseMenuEvent()"
    />
  </div>
</template>

<script>
export default {
  emits: ['closeMenuClick', 'categorySelection', 'logoutClick'],
  methods: {
    emitCloseMenuEvent() {
      this.$emit('closeMenuClick');
    },

    notifyCategortySelection(num) {
      this.$emit('categorySelection', { categoryIndex: num });
    },

    logout() {
      this.$emit('logoutClick');
    },
  },
};
</script>

<style scoped>
ul {
  margin: 15px 0px;
}

ul li {
  @apply text-font27 font-bold;
  padding: 10px 30px;
}

ul li:hover {
  border-right: 7px solid theme('colors.primary-800');
  padding: 10px 43px;
  font-weight: bold;
  cursor: pointer;
}

h3 {
  padding: 10px 30px;
}

.logout {
  @apply font-normal text-font27;
}

div:last-of-type > ul > li:last-of-type:hover {
  border-right: 7px solid theme('colors.light-red-900');
}
.sidebar-container {
  min-height: calc(100% - 100px);
  width: 250px;
  border-radius: 25px 0 0 25px;
}
</style>
