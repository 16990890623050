<template>
  <div class="z-10 mt-40 text-center h-full w-full flex items-center justify-center">
    <div class="text-center mt-5">
      <img
        src="@/assets/svg/empty-orders.svg"
        class="mark inline"
      >
      <p class="my-8 text-2xl font-light text-dark-grey-900 max-w-xs">
        لا يوجد طلبات قيد التحضير، برجاء البدء بتحضير طلب جديد الان
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: { hasOrders: { type: Number, required: true } },
  emits: ['newOrderClick'],
  methods: {
    emitNewOrderClick() {
      this.$emit('newOrderClick');
    },
  },
};
</script>

<style>
html {
  height: 100%;
}

body {
  min-height: 100%;
}
</style>
